<template>
    <r-e-dialog title="选择资产" :visible.sync="dialogVisible" show-footer top="5vh" width="1000px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房源信息">
                    <el-input v-model="currentHouse.address" disabled style="width: 350px;"
                              placeholder="请填写房型名称（15个字以内）"/>
                </el-form-item>
            </div>
            <div class="flex">
                <!--<el-form-item label="资产" prop="assetsUuid">
                    <el-select v-model="formPublish.assetsUuid" filterable style="width: 350px;" placeholder="请选择">
                        <el-option v-for="(item,index) in assetTypeList" :key="index" :label="item.asstesSn+':'+item.asstesName" :value="item.uuid"/>
                    </el-select>
                </el-form-item>-->
                <r-e-table ref="tableRef" v-loading="loading" :data="assetTypeList" :columns="tableColumn"
                           :height="300" row-key-id="uuid" :showPagination="false"
                           @select="selectTable"/>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {getAssetsListByAssetsCode, distribute} from "@/api/assets"
import {MessageSuccess, MessageError} from "@custom/message";
import {numberFormat} from "@custom/index";

export default {
    name: "dialog-add-asset",
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                uuid: null,
                assetsUuid: null,
            },
            rules: {
                assetsUuid: [{required: true, message: '请选择资产', trigger: 'change'}]
            },
            assetTypeList: [],
            tableColumn: [
                {type: "selection", width: 60},
                {prop: "asstesSn", label: "资产编码", width: 200,},
                {prop: "state", label: "状态", width: 150,},
                {prop: "asstesName", label: "资产名称", width: 150,},
                {prop: "assetType", label: "资产类别", width: 150,},
                {prop: "model", label: "规格型号", width: 150,},
                {
                    prop: "price", label: "金额", width: 150,
                    render: (h, {row}) => {
                        return h("span", numberFormat(row.price / 100));
                    },
                },
            ],
            loading: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    methods: {
        //打开当前弹窗方法
        async openDialog(data) {
            const that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            this.loading = true;
            //获取资产列表
            let assetsInfo = await getAssetsListByAssetsCode(data.code).finally(() => loading.close());
            if (assetsInfo) {
                const {list} = assetsInfo;
                this.assetTypeList = list;
            }
            this.loading = false;
            loading.close();
            //打开弹窗
            this.dialogVisible = true;
        },

        //弹框确认事件
        clickSubmit() {
            let that = this;
            // 表单校验
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    //拆分数据
                    let {assetsUuid} = that.formPublish;
                    const {apartmentUuid} = that.currentHouse;
                    //封装数据，调用接口上传数据
                    distribute({uuid: apartmentUuid, assetsUuid}).then(res => {
                        MessageSuccess('资产分配成功');
                        //关闭弹框
                        that.clickCancel();
                        loading.close();
                    }).finally(() => loading.close());
                }
            });
        },

        //弹框取消事件
        clickCancel() {
            //清空表单数据
            this.$refs["formPublish"].resetFields();
            //关闭弹框
            this.dialogVisible = false;
        },

        //表格选择事件
        selectTable(selection, row) {
            //获取选择值
            this.formPublish.assetsUuid = row.uuid;
            //实现单选
            //清空选项
            this.$refs["tableRef"].clearSelection();
            //选择当前项
            this.$refs["tableRef"].toggleRowSelection(row, true);
        },
    }
}
</script>

<style lang="scss" scoped>
/* 禁用全选 */
/deep/ .el-table__header .el-table-column--selection {
    text-indent: -999999px;
}
</style>